import cn from 'classnames';
import { useIntl } from 'react-intl';
import {
  CredentialsPolicy,
  MPREmbeddedApi,
  MPRLauncherRequest,
} from 'MPREmbedded';
import { useState, useRef, useEffect, useMemo } from 'react';

import { useAppSelector } from '@/shared/hooks';
import { Icon, Switch, Tooltip } from '@/shared/ui';
import { CORS_POLICY } from '@/shared/config';

import { MPRModel, MPRDebugModel } from '@/features/MPR';

import { mprControlsDefaultMessages } from '../config/i18n.ts';
import {
  ModeType,
  MPR_INPUT_MODES,
  MPR_TOOLS_BY_MODE_MAP,
} from '../config/constants.ts';

import styles from './MPR2Widget.module.scss';
import { AddToReport } from './AddToReport.tsx';

const mprEmbedded = new MPREmbeddedApi();

const DEBUG_MPR2 = localStorage.getItem('DEBUG_MPR2') === 'true';

const PASS_ALL_SUBVOLUMES_TO_THE_REQUEST =
  localStorage.getItem('PASS_ALL_SUBVOLUMES_TO_THE_REQUEST') === 'true';

const credentialsPolicy: CredentialsPolicy = (/*url: string*/) => CORS_POLICY;

export const MPR2Widget = (props: { toothID: string }) => {
  const { toothID } = props;

  const { formatMessage } = useIntl();

  const [axesVisible, setAxesVisible] = useState(true);
  const [zoomSynced, setZoomSynced] = useState(false);
  const [MPRMode, setMPRMode] = useState<ModeType>('mainMode');

  const mprLauncherRequest = useAppSelector(
    PASS_ALL_SUBVOLUMES_TO_THE_REQUEST
      ? MPRDebugModel.selectors.selectMPREmbeddedLauncherRequestAllSubVolumes(
        toothID,
      )
      : MPRModel.selectors.selectMPREmbeddedLauncherRequest(toothID),
  );

  const ref = useRef<HTMLDivElement>(null);

  // Attach MPREmbedded to the DOM
  useEffect(() => {
    const element = mprEmbedded.getElement();
    if (!ref.current) {
      console.error('[MPR2] ref.current is null. Cannot start MPR2');
      return () => { };
    }

    // IMPORTANT:
    // We get uneasy to debug error which states as follows:
    // Unexpected Application Error!
    // Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
    //
    // Cleanup function like this does not work:
    // return () => {
    //   if (element.parentElement === ref.current) {
    //     ref.current?.removeChild(element);
    //   }
    // }
    //
    // So instead of removing the element on cleanup, we remove previous elements on mount
    const container = ref.current;
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }

    container.appendChild(element);

    // NOTE: If something is wrong, check is application is ready
    // api.applicationReady.on(() => {
    //   console.log('App is ready');
    // });

    mprEmbedded.setCredentialsPolicy(credentialsPolicy);
  }, []);

  // Start MPREmbedded
  useEffect(() => {
    if (!mprLauncherRequest) {
      console.error(
        '[MPR2] MPR Launcher Request is undefined. Cannot start MPREmbedded',
      );
      return () => { };
    }

    if (DEBUG_MPR2) {
      console.log('mprLauncherRequest', mprLauncherRequest);
    }

    // mprLauncherRequest.debug = {
    //   includeMainViewport: true
    // }
    mprEmbedded.start(mprLauncherRequest);
  }, [toothID]);

  // Show/hide cross lines
  useEffect(() => {
    if (axesVisible) {
      mprEmbedded.showCrossLines();
    } else {
      mprEmbedded.hideCrossLines();
    }
  }, [axesVisible]);

  // Enable/disable zoom sync
  useEffect(() => {
    if (zoomSynced) {
      mprEmbedded.enableZoomSync();
    } else {
      mprEmbedded.disableZoomSync();
    }
  }, [zoomSynced]);

  return (
    <>
      {/* Container for tool-changing buttons */}
      <div className={styles.toolsContainer}>
        {MPR_INPUT_MODES.map((mode) => (
          <Tooltip.Primary
            key={mode}
            content={formatMessage(mprControlsDefaultMessages[mode])}
          >
            <Icon
              className={cn(styles.icon, MPRMode === mode && styles.active)}
              size={25}
              name={MPR_TOOLS_BY_MODE_MAP[mode]}
              onClick={() => {
                if (mode === 'reset') {
                  mprEmbedded.reset();
                  mprEmbedded.activateMode('mainMode');
                  setMPRMode('mainMode');
                } else {
                  mprEmbedded.activateMode(mode);
                  setMPRMode(mode);
                }
              }}
            />
          </Tooltip.Primary>
        ))}

        {MPRMode === 'zoomMode' && (
          <Switch
            onChange={() => setZoomSynced(!zoomSynced)}
            checked={zoomSynced}
          >
            {formatMessage({
              id: 'mpr.tools.zoomSynced',
              defaultMessage: 'Zoom synced',
            })}
          </Switch>
        )}
        <Switch
          onChange={() => setAxesVisible(!axesVisible)}
          checked={axesVisible}
        >
          {formatMessage({
            id: 'mpr.tools.axesVisible',
            defaultMessage: 'Axes',
          })}
        </Switch>
      </div>

      {DEBUG_MPR2 && <OpenMPR2Locally request={mprLauncherRequest} />}

      {/* MPR2 Lives inside this container, by attaching itself to the ref */}
      <div ref={ref} className={styles.mprEmbeddedContainer}></div>

      <AddToReport toothID={toothID} mprEmbedded={mprEmbedded} />
    </>
  );
};

const OpenMPR2Locally = (props: { request: MPRLauncherRequest | null }) => {
  const href = useMemo(() => {
    const params: Record<string, string> = {
      request: JSON.stringify(props.request),
      accessToken: localStorage.getItem('accessToken')!,
    };

    return `http://localhost:3002/mpr?${new URLSearchParams(params).toString()}`;
  }, [props.request]);

  return (
    <a href={href} target="_blank">
      Open MPR2 Locally
    </a>
  );
};
