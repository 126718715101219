// @generated by protoc-gen-connect-web v0.6.0 with parameter "target=ts,ts_nocheck=false"
// @generated from file api/core/svc_upload.proto (package com.diagnocat.core, syntax proto3)
/* eslint-disable */

import { AddFilesToUploadSessionReq, AddFilesToUploadSessionResp, ClosePipelinesUploadSessionReq, ClosePipelinesUploadSessionResp, CloseSessionReq, CloseSessionResp, FailSessionReq, FailSessionResp, NotifyFileUploadFinishedReq, NotifyFileUploadFinishedResp, NotifyFileUploadFinishedResp_Upcoming, NotifyFileUploadProgressReq, NotifyFileUploadProgressResp, NotifyFileUploadStartedReq, NotifyFileUploadStartedResp, StartPipelinesUploadSession_UpcomingReq, StartPipelinesUploadSession_UpcomingResp, StartPipelinesUploadSessionReq, StartUploadSessionReq, StartUploadSessionResp } from "./svc_upload_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service com.diagnocat.core.StorageService
 */
export const StorageService = {
  typeName: "com.diagnocat.core.StorageService",
  methods: {
    /**
     *
     * Permission: `UserPermissions.CanEditPersonalData`: user assets
     * Permission: `OrganizationPermissions.CanEditInfo`: AssetType_Organization_Logo || AssetType_Organization_Stamp
     * Permission: `OrganizationPermissions.CanEditStaff`: AssetType_Organization_EmployeeStamp
     * Permission: `PatientPermissions.CanUploadDocuments`: AssetType_Document
     * Permission: `PatientPermissions.CanUploadCBCT`: AssetType_Study_CBCT
     * Permission: `PatientPermissions.CanUploadIOXRay`: AssetType_Study_IntraoralXRay
     * Permission: `PatientPermissions.CanUploadPanoramicXRay`: AssetType_Study_PanoramicXRay
     * Permission: `PatientPermissions.CanUploadSTL`: AssetType_Study_IOS_Meshes
     * Permission: `PatientPermissions.CanUploadDentalPhoto`: AssetType_Study_DentalPhoto
     * Permission: `ReportPermissions.CanChangeReportAssets`: report assets
     * Errors:
     * 1. Multiple images were provided for the pano study
     * - type: UserBadRequestError, Code: multiple_panoramic_xrays_provided
     *
     * @generated from rpc com.diagnocat.core.StorageService.StartUploadSession
     */
    startUploadSession: {
      name: "StartUploadSession",
      I: StartUploadSessionReq,
      O: StartUploadSessionResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.StartPipelinesUploadSession
     */
    startPipelinesUploadSession: {
      name: "StartPipelinesUploadSession",
      I: StartPipelinesUploadSessionReq,
      O: StartUploadSessionResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.StartPipelinesUploadSession_Upcoming
     */
    startPipelinesUploadSession_Upcoming: {
      name: "StartPipelinesUploadSession_Upcoming",
      I: StartPipelinesUploadSession_UpcomingReq,
      O: StartPipelinesUploadSession_UpcomingResp,
      kind: MethodKind.Unary,
    },
    /**
     * only if UploadSession.Closed == false
     *
     * @generated from rpc com.diagnocat.core.StorageService.AddFilesToUploadSession
     */
    addFilesToUploadSession: {
      name: "AddFilesToUploadSession",
      I: AddFilesToUploadSessionReq,
      O: AddFilesToUploadSessionResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.NotifyFileUploadStarted
     */
    notifyFileUploadStarted: {
      name: "NotifyFileUploadStarted",
      I: NotifyFileUploadStartedReq,
      O: NotifyFileUploadStartedResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.NotifyFileUploadProgress
     */
    notifyFileUploadProgress: {
      name: "NotifyFileUploadProgress",
      I: NotifyFileUploadProgressReq,
      O: NotifyFileUploadProgressResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.NotifyFileUploadFinished
     */
    notifyFileUploadFinished: {
      name: "NotifyFileUploadFinished",
      I: NotifyFileUploadFinishedReq,
      O: NotifyFileUploadFinishedResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.NotifyFileUploadFinished_Upcoming
     */
    notifyFileUploadFinished_Upcoming: {
      name: "NotifyFileUploadFinished_Upcoming",
      I: NotifyFileUploadFinishedReq,
      O: NotifyFileUploadFinishedResp_Upcoming,
      kind: MethodKind.ServerStreaming,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.CloseSession
     */
    closeSession: {
      name: "CloseSession",
      I: CloseSessionReq,
      O: CloseSessionResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.ClosePipelinesUploadSession
     */
    closePipelinesUploadSession: {
      name: "ClosePipelinesUploadSession",
      I: ClosePipelinesUploadSessionReq,
      O: ClosePipelinesUploadSessionResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc com.diagnocat.core.StorageService.FailSession
     */
    failSession: {
      name: "FailSession",
      I: FailSessionReq,
      O: FailSessionResp,
      kind: MethodKind.Unary,
    },
  }
} as const;

