import { useAppSelector } from '@/shared/hooks';
import { StudyType } from '@/shared/api/protocol-ts/model/dto_study_pb';
import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import {
  ReportRequest_InputType_CBCT_IOS_Superimposition,
  ReportRequest_InputType_StudioImplant,
  ReportRequest_InputType_StudioOrtho,
} from '@/shared/api/protocol-ts/model/dto_report_request_pb';
import { getImageSrc } from '@/shared/lib';

import { billingModel } from '@/entities/billing';
import {
  SegmentationReportTypes,
  useNavigateToReport,
} from '@/entities/reports';
import { assetsModel } from '@/entities/assets';
import { patientModel } from '@/entities/patient';

import { useNewReport } from '@/features/addNewReport';
import { StructuredReportsAndStudiesType } from '@/features/reportCards';
import { useRemoveReport } from '@/features/removeReport';
import { useRemoveStudy } from '@/features/removeStudy';
import { useDownloadReportPDF } from '@/features/downloadReportPDF';

import {
  isSecondaryStudy,
  isStudyOfType,
  getRelevantStudy,
  getSegmentronPreview,
} from '../lib';

export const useReportCard = (
  structuredReportsAndStudies: StructuredReportsAndStudiesType,
) => {
  const { CanRequestReport } = useAppSelector(
    patientModel.selectors.selectPatientYourPermissions(
      structuredReportsAndStudies.report?.PatientID ?? '',
    ),
  );

  const {
    requestIOXRayGPReport,
    requestPanoGPReport,
    requestCBCTGPReport,
    requestCBCTSegmentationReport,
    requestStudioOrthoReport,
    requestStudioImplantReport,
    requestCBCTIOSSuperimpositionReport,
    requestCBCTOrthoReport,
    requestIOSSegmentationReport,
  } = useNewReport();
  const removeReport = useRemoveReport();
  const removeStudy = useRemoveStudy();

  const reportType = structuredReportsAndStudies.reportType;

  const report = structuredReportsAndStudies?.report;
  const studies = structuredReportsAndStudies?.studies;
  const date = structuredReportsAndStudies?.date;

  const relevantStudy = getRelevantStudy(studies);
  const relevantStudyType = relevantStudy?.Type;

  const patientID = report?.PatientID;

  const reportID = report?.ID;
  const reportStatus = report?.Status;
  const isReportGenerationCompleted = reportStatus?.Completed;
  const isReportSigned = report?.Signature?.Signed;
  const isReportFailed = reportStatus?.Failed;

  const isSegmentronReport = SegmentationReportTypes.includes(reportType);
  const isIntraoralScanReport =
    reportType === ReportType.ReportType_IOS_Segmentation;
  const isCBCTOrthoPDFReport =
    reportType === ReportType.ReportType_CBCT_OrthoSlides;

  const isReportOrderAvailable = useAppSelector(
    billingModel.selectors.selectIsReportOrderAvailableByStudyType(
      relevantStudyType ?? StudyType.StudyType_InvalidValue,
    ),
  );

  const CBCTOrthoPDFUrl = useAppSelector(
    assetsModel.selectors.selectCBCTOrthoPDFUrl(report?.ID ?? ''),
  );

  const navigateToReport = useNavigateToReport();

  const { downloadReportPDF, downloading } = useDownloadReportPDF(
    reportID ?? '',
  );

  const downloadReportHandler = async () => {
    if (
      isCBCTOrthoPDFReport ||
      reportType === ReportType.ReportType_StudioOrtho
    ) {
      window.open(CBCTOrthoPDFUrl, '_blank', 'noopener,noreferrer');
    } else {
      await downloadReportPDF();
    }
  };

  const isPanowingsReport =
    relevantStudyType === StudyType.StudyType_PanoramicXRay &&
    report?.Specific.case === 'DataPanoBitewings';

  const isIOXRayOrPanowings =
    relevantStudyType === StudyType.StudyType_IntraoralXRay ||
    isPanowingsReport;

  const previewImageSrc = getImageSrc(
    report?.DisplayAssetID || relevantStudy?.DisplayAssetID,
    'preview',
  );

  const cardPreviewSrc = isSegmentronReport
    ? getSegmentronPreview(reportType)
    : previewImageSrc;

  const handleCardClick = () => {
    if (isReportGenerationCompleted && !isReportFailed) {
      navigateToReport({
        reportType,
        reportID: reportID ?? '',
        patientID: patientID ?? '',
        CBCTOrthoPDFUrl,
      });
    }
  };

  const reorderReport = () => {
    const CBCTStudyID = studies?.find(
      isStudyOfType(StudyType.StudyType_CBCT),
    )?.ID;
    const IOXRayStudyID = studies?.find(
      isStudyOfType(StudyType.StudyType_IntraoralXRay),
    )?.ID;
    const panoStudyID = studies?.find(
      isStudyOfType(StudyType.StudyType_PanoramicXRay),
    )?.ID;
    const IOSMeshesStudyID = studies?.find(
      isStudyOfType(StudyType.StudyType_IOS_Meshes),
    )?.ID;
    const dentalPhotoStudyID = studies?.find(
      isStudyOfType(StudyType.StudyType_DentalPhoto),
    )?.ID;

    if (CBCTStudyID) {
      if (reportType === ReportType.ReportType_CBCT_GP) {
        requestCBCTGPReport(CBCTStudyID);
      }

      if (reportType === ReportType.ReportType_CBCT_Segmentation) {
        requestCBCTSegmentationReport(CBCTStudyID);
      }

      if (reportType === ReportType.ReportType_CBCT_IOS_Superimposition) {
        requestCBCTIOSSuperimpositionReport(
          new ReportRequest_InputType_CBCT_IOS_Superimposition({
            CBCTStudyID,
            STLStudyID: IOSMeshesStudyID,
            DentalPhotoStudyID: dentalPhotoStudyID,
          }),
        );
      }

      if (reportType === ReportType.ReportType_StudioImplant) {
        requestStudioImplantReport(
          new ReportRequest_InputType_StudioImplant({
            CBCTStudyID,
            IOSMeshesStudyID,
            DentalPhotoStudyID: dentalPhotoStudyID,
          }),
        );
      }

      if (reportType === ReportType.ReportType_CBCT_OrthoSlides) {
        requestCBCTOrthoReport(CBCTStudyID);
      }

      if (reportType === ReportType.ReportType_StudioOrtho) {
        const secondaryCBCTStudyID = studies?.find(
          isSecondaryStudy(CBCTStudyID, StudyType.StudyType_CBCT),
        )?.ID;

        requestStudioOrthoReport(
          new ReportRequest_InputType_StudioOrtho({
            CBCTStudyID,
            IOSMeshesStudyID,
            DentalPhotoStudyID: dentalPhotoStudyID,
            SecondaryCBCTStudyID: secondaryCBCTStudyID,
          }),
        );
      }
    }

    if (IOXRayStudyID && reportType === ReportType.ReportType_IOXRay_GP) {
      requestIOXRayGPReport(IOXRayStudyID);
    }

    if (panoStudyID && reportType === ReportType.ReportType_Pano_GP) {
      requestPanoGPReport(panoStudyID);
    }

    if (
      IOSMeshesStudyID &&
      reportType === ReportType.ReportType_IOS_Segmentation
    ) {
      requestIOSSegmentationReport(IOSMeshesStudyID);
    }
  };

  const removeReportHandler = async () => {
    const response = await removeReport(
      structuredReportsAndStudies?.activeReportIDs,
    );

    // NOTE: A temporary solution until ReportType_IOS_Segmentation is fully implemented
    if (response && isIntraoralScanReport) {
      await removeStudy(relevantStudy?.ID ?? '');
    }
  };

  return {
    isReportGenerationCompleted,
    isIOXRayOrPanowings,
    isPanowingsReport,
    isSegmentronReport,
    reportType,
    previewImageSrc,
    report,
    relevantStudy,
    date,
    isReportFailed,
    isReportSigned,
    isReportOrderAvailable,
    isLoadingPDF: downloading,
    isCBCTOrthoPDFReport,
    CanRequestReport,
    cardPreviewSrc,
    isIntraoralScanReport,
    handleCardClick,
    reorderReport,
    downloadReportHandler,
    removeReportHandler,
  };
};
