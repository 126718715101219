import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { Modal } from '@/shared/ui';

import { ModalID, modalModel } from '@/entities/modal';

import { OrderReport } from '@/widgets/OrderReport';

import styles from './MobileOrderReportModal.module.scss';

export const MobileOrderReportModal = () => {
  const dispatch = useAppDispatch();

  const { visible } = useAppSelector(
    (state) => state.modal[ModalID.MobileOrderReportModal],
  );

  const handleClose = useCallback(() => {
    dispatch(modalModel.actions.closeModal(ModalID.MobileOrderReportModal));
  }, [dispatch]);

  return (
    <Modal
      isOpen={visible}
      onCancel={handleClose}
      className={styles.container}
      hideFooter
    >
      <OrderReport />
    </Modal>
  );
};
