import { FC } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button, Icon } from '@/shared/ui';
import { useAppDispatch } from '@/shared/hooks';

import { ModalID, modalModel } from '@/entities/modal';
import { ensureRefreshImageSrc } from '@/entities/assets';

import { StructuredReportsAndStudiesType } from '@/features/reportCards';

import { ReportPreviewPlaceholder } from '../ReportPreviewPlaceholder/ReportPreviewPlaceholder.tsx';
import { ReportGenerationStatus } from '../ReportGenerationStatus/ReportGenerationStatus';
import { reportCardTitle } from '../../config';
import { DetailsPopover } from '../DetailsPopover/DetailsPopover';
import { useReportCard } from '../../hooks';

import styles from './ReportCard.module.scss';

type ReportCardProps = {
  structuredReportsAndStudies: StructuredReportsAndStudiesType;
  className?: string;
  testID?: string;
};

export const ReportCard: FC<ReportCardProps> = (props) => {
  const { structuredReportsAndStudies, className, testID } = props;

  const dispatch = useAppDispatch();
  const { formatMessage, formatDate } = useIntl();
  const {
    isReportGenerationCompleted,
    isIOXRayOrPanowings,
    isPanowingsReport,
    reportType,
    date,
    report,
    isReportFailed,
    isReportSigned,
    isReportOrderAvailable,
    isLoadingPDF,
    cardPreviewSrc,
    isIntraoralScanReport,
    CanRequestReport,
    handleCardClick,
    reorderReport,
    downloadReportHandler,
    removeReportHandler,
  } = useReportCard(structuredReportsAndStudies);

  if (!report) {
    return;
  }

  const { CanDelete = false } = report?.YourPermissions ?? {};

  const openReportInfoModal = () => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.ReportInfo,
        data: {
          sourceStudyIDs: report.SourceStudyIDs,
          reportType: report.Type,
        },
      }),
    );
  };

  const backgroundColorStyle =
    isReportGenerationCompleted && cardPreviewSrc && !isIntraoralScanReport
      ? styles.blackBackground
      : styles.whiteBackground;

  const textColorStyle =
    isReportGenerationCompleted && cardPreviewSrc
      ? styles.textWhite
      : styles.textBlack;

  return (
    <div
      className={cn(
        styles.container,
        isReportGenerationCompleted &&
          cardPreviewSrc &&
          styles.reportGenerationCompleted,
        backgroundColorStyle,
        className,
      )}
      data-testid={testID}
      onClick={handleCardClick}
    >
      {isReportGenerationCompleted && reportType && cardPreviewSrc && (
        <img
          className={cn(
            styles.previewImage,
            isIOXRayOrPanowings && styles.IOXRayOrPanowingsPreview,
          )}
          onError={ensureRefreshImageSrc}
          src={cardPreviewSrc}
          alt={formatMessage(reportCardTitle[reportType])}
        />
      )}

      <header className={cn(styles.header, textColorStyle)}>
        <h4 className={cn(styles.title, textColorStyle, 'h4')}>
          {formatMessage(reportCardTitle[reportType])}

          <DetailsPopover
            canDelete={CanDelete}
            isReportGenerationCompleted={Boolean(isReportGenerationCompleted)}
            isLoadingPDF={isLoadingPDF}
            onDetailsView={openReportInfoModal}
            onRemoveReport={removeReportHandler}
            onDownloadReport={downloadReportHandler}
          />
        </h4>

        {date
          ? formatDate(date, {
              dateStyle: 'medium',
            })
          : formatMessage({
              id: 'reportCard.unknownDate',
              defaultMessage: 'Unknown date',
            })}
      </header>

      {!isReportGenerationCompleted && (
        <ReportGenerationStatus report={report} />
      )}

      {isReportGenerationCompleted && !cardPreviewSrc && (
        <ReportPreviewPlaceholder />
      )}

      {(isReportSigned || isReportFailed) && (
        <footer className={cn(styles.footer, 'p2')}>
          {isReportSigned && (
            <div className={styles.signed}>
              <Icon name="check" size={24} />

              <FormattedMessage
                id="reportCard.signed"
                defaultMessage="Signed"
              />
            </div>
          )}

          {isReportFailed && CanRequestReport && !isPanowingsReport && (
            <Button
              variant="secondary"
              onClick={(event) => {
                event.stopPropagation();

                reorderReport();
              }}
              size="medium"
              className={styles.reorder}
              disabled={isReportOrderAvailable}
            >
              <FormattedMessage
                id="reportCard.reorder"
                defaultMessage="Reorder"
              />
            </Button>
          )}
        </footer>
      )}
    </div>
  );
};
