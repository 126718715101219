import { FC } from 'react';

import {
  MobileBasicLayout,
  MobileBasicLayoutProps,
} from './components/MobileBasicLayout/MobileBasicLayout';
import { MobileHeader } from './components/MobileHeader/MobileHeader';
import { MobileContent } from './components/MobileContent/MobileContent';
import { MobileSider } from './components/MobileSider/MobileSider';
import { MobileMain } from './components/MobileMain/MobileMain';
import { MobileFooter } from './components/MobileFooter/MobileFooter';

type CompoundedComponent = FC<MobileBasicLayoutProps> & {
  Header: typeof MobileHeader;
  Content: typeof MobileContent;
  Main: typeof MobileMain;
  Sider: typeof MobileSider;
  Footer: typeof MobileFooter;
};

const MobileLayout = MobileBasicLayout as CompoundedComponent;

MobileLayout.Header = MobileHeader;
MobileLayout.Content = MobileContent;
MobileLayout.Main = MobileMain;
MobileLayout.Sider = MobileSider;
MobileLayout.Footer = MobileFooter;

export { MobileLayout };
