import { FormattedMessage, useIntl } from 'react-intl';
import { useCallback } from 'react';
import cn from 'classnames';

import { Button } from '@/shared/ui';
import { useAppDispatch, useAppSelector } from '@/shared/hooks';
import { OrderReportType } from '@/shared/config';
import { ServiceType } from '@/shared/api/protocol-ts/api/billing_new/dto_services_new_pb';
import { orderReportName } from '@/shared/i18n';
import { FeatureFlag } from '@/shared/api/protocol-ts/model/dto_organization_pb';

import { ModalID, modalModel } from '@/entities/modal';
import { billingModel } from '@/entities/billing';
import { organizationModel } from '@/entities/organization';

import styles from './OrderReport.module.scss';

export const OrderReport = () => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const services = useAppSelector(
    billingModel.selectors.selectActiveServicesList,
  );

  const showIOSReportButton = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_IOSReportButton,
    ),
  );
  const openUploadStudyModal = useCallback(
    (reportType: OrderReportType) => {
      dispatch(
        modalModel.actions.openModal({
          modalID: ModalID.OrderReport,
          data: { reportType },
        }),
      );
    },
    [dispatch],
  );

  return (
    <>
      <div className={styles.header}>
        <h4 className={cn(styles.title, 'h4')}>
          <FormattedMessage
            id="orderReport.title"
            defaultMessage="Order AI report"
          />
        </h4>
      </div>

      <div
        className={styles.ordersWrapper}
        data-count={Object.keys(services).length}
      >
        {ServiceType.ServiceType_Order_CBCT_GP_Analysis in services && (
          <Button
            onClick={() => openUploadStudyModal('CBCT')}
            variant="secondary"
            icon="cbct3d"
            className={styles.orderButton}
            disabled={!services[ServiceType.ServiceType_Order_CBCT_GP_Analysis]}
          >
            {formatMessage(orderReportName['CBCT'])}
          </Button>
        )}

        {ServiceType.ServiceType_Order_Panoramic_GP_Analysis in services && (
          <Button
            onClick={() => openUploadStudyModal('Pano')}
            variant="secondary"
            icon="pano3d"
            className={styles.orderButton}
            disabled={
              !services[ServiceType.ServiceType_Order_Panoramic_GP_Analysis]
            }
          >
            {formatMessage(orderReportName['Pano'])}
          </Button>
        )}

        {ServiceType.ServiceType_Order_IOXRay_GP_Analysis in services && (
          <Button
            onClick={() => openUploadStudyModal('IOXRay')}
            variant="secondary"
            icon="ios3d"
            className={styles.orderButton}
            disabled={
              !services[ServiceType.ServiceType_Order_IOXRay_GP_Analysis]
            }
          >
            {formatMessage(orderReportName['IOXRay'])}
          </Button>
        )}

        {(ServiceType.ServiceType_Order_CBCT_STL_Analysis in services ||
          ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis in
            services) && (
          <Button
            onClick={() => openUploadStudyModal('3DStudio')}
            variant="secondary"
            icon="model3d"
            className={styles.orderButton}
            disabled={
              !services[ServiceType.ServiceType_Order_CBCT_STL_Analysis] &&
              !services[
                ServiceType.ServiceType_Order_CBCT_IOS_Superimposition_Analysis
              ]
            }
          >
            {formatMessage(orderReportName['3DStudio'])}
          </Button>
        )}

        {ServiceType.ServiceType_Order_ImplantStudio_Analysis in services && (
          <Button
            onClick={() => openUploadStudyModal('ImplantStudio')}
            variant="secondary"
            icon="implant3d"
            className={styles.orderButton}
            disabled={
              !services[ServiceType.ServiceType_Order_ImplantStudio_Analysis]
            }
          >
            {formatMessage(orderReportName['ImplantStudio'])}
          </Button>
        )}

        {(ServiceType.ServiceType_Order_OrthoStudio_Analysis in services ||
          ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis in
            services) && (
          <Button
            onClick={() => openUploadStudyModal('OrthoStudio')}
            variant="secondary"
            icon="ortho3d"
            className={styles.orderButton}
            disabled={
              !services[ServiceType.ServiceType_Order_OrthoStudio_Analysis] &&
              !services[ServiceType.ServiceType_Order_CBCT_Orthodontic_Analysis]
            }
          >
            {formatMessage(orderReportName['OrthoStudio'])}
          </Button>
        )}
        {showIOSReportButton &&
          ServiceType.ServiceType_Order_IOS_Segmentation_Analysis in
            services && (
            <Button
              onClick={() => openUploadStudyModal('IOSSegmentation')}
              variant="secondary"
              icon="model3d"
              className={styles.orderButton}
              disabled={
                !services[
                  ServiceType.ServiceType_Order_IOS_Segmentation_Analysis
                ]
              }
            >
              {formatMessage(orderReportName['IOSSegmentation'])}
            </Button>
          )}
      </div>
    </>
  );
};
