import React, { FC } from 'react';
import cn from 'classnames';

import styles from './MobileFooter.module.scss';

type MobileFooterProps = {
  settingsFooter?: boolean;
  className?: string;
  testID?: string;
  children?: React.ReactNode;
  sticky?: boolean;
  isFixed?: boolean;
};

export const MobileFooter: FC<MobileFooterProps> = (props) => {
  const {
    settingsFooter = false,
    className,
    testID,
    children,
    sticky,
    isFixed,
  } = props;

  return (
    <footer
      className={cn(
        styles.container,
        sticky && styles.sticky,
        settingsFooter && styles.settingsFooter,
        isFixed && styles.fixed,
        className,
      )}
      data-testid={testID}
    >
      {children}
    </footer>
  );
};
