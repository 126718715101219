import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';

import { ReportType } from '@/shared/api/protocol-ts/model/dto_report_pb';
import { trackPageView } from '@/shared/lib';
import { MobileLayout } from '@/shared/ui';
import { useMedia } from '@/shared/hooks';

import { ReportLayout, i18n } from '@/entities/reports';

import { MaskTooltip, MaskFilters } from '@/features/renderMasks';

import { Conclusion } from '@/widgets/Conclusion';
import { PanowingsReportRender } from '@/widgets/PanowingsReportRender';
import { ReportActions } from '@/widgets/ReportActions';
import { Report2DToothChart } from '@/widgets/Report2DToothChart';
import { ReportToothCardList } from '@/widgets/ReportToothCardList';
import { MobileHeader } from '@/widgets/Header';

import { Toolbar } from '../Toolbar/Toolbar';
import { PanowingImageSlider } from '../PanowingImageSlider/PanowingImageSlider';
import { PanowingChangeTeethNumberModal } from '../PanowingChangeTeethNumberModal/PanowingChangeTeethNumberModal';

import styles from './PanowignsReport.module.scss';

export const PanowingsReport = () => {
  const { patientID, reportID } = useParams();
  const { formatMessage } = useIntl();
  const { isMobile } = useMedia();

  useEffect(() => {
    trackPageView('PanowingsReport Page Viewed');
  }, []);

  if (isMobile) {
    return (
      <MobileLayout>
        <MobileHeader />
        <MobileLayout.Main>
          <PanowingsReportRender>
            <MaskFilters />
            <MaskTooltip />
            <Toolbar />
            <PanowingImageSlider />
          </PanowingsReportRender>
          <Report2DToothChart />
          <ReportToothCardList />
          <Conclusion />
        </MobileLayout.Main>
        <MobileLayout.Footer isFixed>
          <ReportActions
            patientID={patientID as string}
            reportID={reportID as string}
          />
        </MobileLayout.Footer>

        {/* Modals */}
        <PanowingChangeTeethNumberModal />
      </MobileLayout>
    );
  }

  return (
    <>
      <ReportLayout.LeftColumn>
        <h1>{formatMessage(i18n[ReportType.ReportType_Pano_Bitewings])}</h1>
        <PanowingsReportRender>
          <MaskFilters className={styles.maskFilters} />
          <MaskTooltip />
          <Toolbar />
          <PanowingImageSlider />
        </PanowingsReportRender>
      </ReportLayout.LeftColumn>
      <ReportLayout.RightColumn>
        <Report2DToothChart />

        <ReportToothCardList />

        <Conclusion />

        <ReportActions
          patientID={patientID as string}
          reportID={reportID as string}
        />
      </ReportLayout.RightColumn>
      {/* Modals */}
      <PanowingChangeTeethNumberModal />
    </>
  );
};
