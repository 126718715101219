import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileWithPath } from 'react-dropzone';

import {
  Button,
  Column,
  Layout,
  Select,
  Tabs,
  WidgetCard,
  WidgetLayout,
} from '@/shared/ui/';
import {
  useAppDispatch,
  useAppSelector,
  useMedia,
  useTab,
} from '@/shared/hooks';
import { AssetType } from '@/shared/api/protocol-ts/model/dto_asset_pb';
import {
  DentalNotationFormat,
  FeatureFlag,
  OrganizationSettings,
  OrganizationUserRoleType,
} from '@/shared/api/protocol-ts/model/dto_organization_pb';
import { getImageSrc, trackPageView, validateImageFile } from '@/shared/lib';

import {
  organizationModel,
  useCountriesOptions,
} from '@/entities/organization';
import { ModalID, modalModel } from '@/entities/modal';
import { userModel } from '@/entities/user';
import { billingModel } from '@/entities/billing';

import { UploadImage } from '@/features/uploadImage';
import { BillingInformation } from '@/features/billingInformation';
import { useUploadAssetContext } from '@/features/uploadAsset';
import { Footer } from '@/features/footer';
import { RemoveAssetModal, useRemoveAssetModal } from '@/features/removeAsset';

import { PermissionsModal } from '@/widgets/PermissionsModal';
import { AddStaffModal } from '@/widgets/AddStaffModal';
import { Header } from '@/widgets/Header';
import { EditCompanyInfoModal } from '@/widgets/EditCompanyInfoModal';
import { GetFullAccessModal } from '@/widgets/GetFullAccessModal';
import { BillingInformationModal } from '@/widgets/BillingInformationModal';
import { Invoices } from '@/widgets/Invoices';
import { CurrentSubscription, Packages } from '@/widgets/CurrentSubscription';
import { SubscriptionPlans } from '@/widgets/SubscriptionPlans';
import { AboutLabelingModal } from '@/widgets/AboutLabelingModal';

import { useOrganizationSettingsStream } from '@/app/hooks/useOrganizationSettingsStream';

import { getTabValuesByDoctorRoles } from './lib/getTabValuesByDoctorRoles';
import { StaffMembers } from './ui/StaffMembers/StaffMembers';
import {
  CompanyInfoTitles,
  CONTACTS_SORT,
  DENTAL_NOTATIONS,
  TabValue,
} from './config/constants';
import { companyInfoTitles, companySettingsTabLabels } from './config/i18n';
import styles from './CompanySettings.module.scss';

type CompanySettingsProps = {
  className?: string;
};

export const CompanySettings: FC<CompanySettingsProps> = (props) => {
  const { className } = props;

  const { tabValue, handleTabChange } = useTab({
    name: 'tab',
  });

  const [stampLoading, setStampLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);

  const [logoUploadError, setLogoUploadError] = useState('');
  const [stampUploadError, setStampUploadError] = useState('');

  const { isMobile, isPhone } = useMedia();

  const organization = useAppSelector(
    organizationModel.selectors.selectCurrentOrganization,
  );
  const user = useAppSelector(userModel.selectors.selectCurrentUser);
  const userLocale = useAppSelector(userModel.selectors.selectUserLocale);
  const { ID: billingAccountID } = useAppSelector(
    billingModel.selectors.selectAccount,
  );

  const currentTeamMember = useAppSelector(
    organizationModel.selectors.selectUserByID(user.ID),
  );

  const isShopAvailable = useAppSelector(
    organizationModel.selectors.selectFeatureFlag(
      FeatureFlag.FeatureFlag_Show_BillingShop,
    ),
  );

  useOrganizationSettingsStream();

  const countryOptions = useCountriesOptions(userLocale);

  const currentCountry = useMemo(
    () =>
      countryOptions?.find(
        (countryOption) =>
          countryOption.value === organization?.Contacts?.Country,
      )?.label ?? '',
    [countryOptions, organization],
  );
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const organizationLogo = getImageSrc(
    organization?.LogoAssetID ?? '',
    'preview',
  );
  const organizationStamp = getImageSrc(
    organization?.StampAssetID ?? '',
    'preview',
  );

  const tabList = getTabValuesByDoctorRoles(
    currentTeamMember?.Roles ?? [],
    isShopAvailable,
  );

  const { startUploadAsset: startUploadImage } = useUploadAssetContext();

  const {
    isRemoveAssetModalOpen,
    openRemoveAssetModal,
    closeRemoveAssetModal,
    currentAssetID,
  } = useRemoveAssetModal();

  const handleOpenModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.EditCompanyInfoModal,
      }),
    );
  }, [dispatch]);

  const handleChange = async (image: FileWithPath, assetType: AssetType) => {
    if (assetType === AssetType.AssetType_Organization_Stamp) {
      const isImageInvalid = validateImageFile(
        image,
        (message) => setStampUploadError(message),
        formatMessage,
      );

      if (isImageInvalid) return;

      setStampLoading(true);
    } else {
      const isImageInvalid = validateImageFile(
        image,
        (message) => setLogoUploadError(message),
        formatMessage,
      );

      if (isImageInvalid) return;

      setLogoLoading(true);
    }

    try {
      if (image) {
        await startUploadImage(
          {
            files: [image],
            userID: user?.ID,
            assetType,
            meta: {
              studyType: assetType,
              patientName: user?.PersonalData?.FirstName,
            },
          },
          true,
        );
      }
    } catch (error) {
      const errorMessage = formatMessage({
        id: 'uploadImage.serverError',
        defaultMessage:
          'Server error: Image upload failed due to server error. Please retry later',
      });

      if (assetType === AssetType.AssetType_Organization_Stamp) {
        setStampUploadError(errorMessage);
      } else {
        setLogoUploadError(errorMessage);
      }
    } finally {
      setLogoLoading(false);
      setStampLoading(false);
    }
  };

  const handleChangeNotationFormat = async (
    newFormat: DentalNotationFormat,
  ) => {
    if (organization?.ID) {
      await dispatch(
        organizationModel.thunks.setOrganizationSettings({
          OrganizationID: organization.ID,
          Settings: {
            DentalNotationFormat: newFormat,
          } as OrganizationSettings,
        }),
      );
    }
  };

  const handleOpenAddStaffModal = useCallback(() => {
    dispatch(
      modalModel.actions.openModal({
        modalID: ModalID.AddStaffModal,
        data: { actionType: 'Add', editingUserID: user?.ID },
      }),
    );
  }, [dispatch, user?.ID]);

  const contacts = Object.keys(organization?.Contacts || {}).sort(
    (a, b) => CONTACTS_SORT.indexOf(a) - CONTACTS_SORT.indexOf(b),
  ) as CompanyInfoTitles[];

  const hasContacts = !!contacts.length;

  const notationFormat =
    organization?.Settings?.DentalNotationFormat ||
    DentalNotationFormat.DentalNotationFormatISO;

  const handleDelete = async (assetID: string) => {
    openRemoveAssetModal(assetID);
    setStampUploadError('');
    setLogoUploadError('');
  };

  useEffect(() => {
    trackPageView('CompanySettings Page Viewed');
  }, []);

  useEffect(() => {
    const showBilling =
      billingAccountID &&
      isShopAvailable &&
      currentTeamMember?.Roles?.includes(
        OrganizationUserRoleType.OrganizationUserRoleTypeOwner,
      );

    const getAvailableProducts = async () => {
      await dispatch(
        billingModel.thunks.getAvailableProducts({
          Condition: { case: 'ByAccountID', value: billingAccountID },
        }),
      ).unwrap();
    };

    if (showBilling) {
      getAvailableProducts();
    }
  }, [billingAccountID, isShopAvailable, currentTeamMember]);

  return (
    <Layout>
      <Layout.Header>
        <Header back />
      </Layout.Header>

      <Layout.Content>
        <Layout.Main>
          <div className={cn(styles.container, className)}>
            <h1 className="h3">{organization?.Name}</h1>
          </div>

          <Tabs.Root
            className={styles.columnsContainer}
            value={tabValue ?? 'CompanyInfo'}
            onValueChange={handleTabChange}
          >
            <Tabs.List>
              {tabList.map((tabItem) => (
                <Tabs.Trigger
                  key={tabItem}
                  className={styles.tabs}
                  value={tabItem}
                >
                  {formatMessage(companySettingsTabLabels[tabItem])}
                </Tabs.Trigger>
              ))}

              {tabValue === 'Staff' && (
                <Button
                  variant="primary"
                  onClick={handleOpenAddStaffModal}
                  icon="plus"
                  size={isMobile ? 'medium' : 'large'}
                  className={styles.addStaffButton}
                >
                  <FormattedMessage
                    id="companySettings.addTeamMember"
                    defaultMessage="Add team member"
                  />
                </Button>
              )}
            </Tabs.List>

            <Tabs.Content value={TabValue.CompanyInfo}>
              <WidgetLayout>
                <Column>
                  <WidgetCard className={styles.widgetCard}>
                    <div className={styles.accountInfo}>
                      <div className={styles.organizationDescription}>
                        <h4 className={isPhone ? 'h5' : 'h4'}>
                          {organization?.Name}
                        </h4>

                        <p className={cn(styles.subText, 'p2')}>
                          <FormattedMessage
                            id="companySettings.clinicDescription"
                            defaultMessage="Clinic information will be a part of patient reports"
                          />
                        </p>
                      </div>

                      <Button
                        onClick={handleOpenModal}
                        size="medium"
                        disabled={!organization?.YourPermissions?.CanEditInfo}
                        variant="tertiary"
                      >
                        <FormattedMessage
                          id="global.edit"
                          defaultMessage="Edit"
                        />
                      </Button>
                    </div>

                    {hasContacts && (
                      <div className={styles.companyInfoItems}>
                        {contacts.map((item: CompanyInfoTitles) => (
                          <div key={item} className={styles.companyInfoItem}>
                            <p className={cn(styles.infoItemTitle, 'p2')}>
                              {formatMessage(companyInfoTitles[item])}
                            </p>

                            <p className="p2">
                              {item === CompanyInfoTitles.Country
                                ? currentCountry
                                : organization?.Contacts &&
                                  organization?.Contacts[item]}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </WidgetCard>
                </Column>

                <Column>
                  <WidgetCard className={styles.clinicImages}>
                    <h4 className={isPhone ? 'h5' : 'h4'}>
                      <FormattedMessage
                        id="companySettings.clinicImages"
                        defaultMessage="Clinic images"
                      />
                    </h4>

                    <div className={styles.stampAndSignature}>
                      <UploadImage
                        previewImage={
                          organization?.LogoAssetID && organizationLogo
                        }
                        loading={logoLoading}
                        imageAssetID={organization?.LogoAssetID}
                        onChange={(image) =>
                          handleChange(
                            image,
                            AssetType.AssetType_Organization_Logo,
                          )
                        }
                        error={logoUploadError}
                        onRemove={handleDelete}
                        title={
                          <FormattedMessage
                            id="companySettings.logo"
                            defaultMessage="Logo"
                          />
                        }
                        dropDescription={
                          <FormattedMessage
                            id="global.dragDropPhotos"
                            defaultMessage="Drag image or add from your computer"
                          />
                        }
                      />

                      <UploadImage
                        previewImage={
                          organization?.StampAssetID && organizationStamp
                        }
                        loading={stampLoading}
                        onChange={(image) =>
                          handleChange(
                            image,
                            AssetType.AssetType_Organization_Stamp,
                          )
                        }
                        error={stampUploadError}
                        imageAssetID={organization?.StampAssetID}
                        onRemove={handleDelete}
                        title={
                          <FormattedMessage
                            id="companySettings.stamp"
                            defaultMessage="Stamp"
                          />
                        }
                        dropDescription={
                          <FormattedMessage
                            id="global.dragDropPhotos"
                            defaultMessage="Drag image or add from your computer"
                          />
                        }
                      />
                    </div>
                  </WidgetCard>

                  <WidgetCard className={styles.widgetCard}>
                    <h4 className={isPhone ? 'h5' : 'h4'}>
                      {formatMessage({
                        id: 'settings.dataSettings',
                        defaultMessage: 'Data settings',
                      })}
                    </h4>

                    <div className={styles.dentalSettings}>
                      <p className="p2">
                        {formatMessage({
                          id: 'settings.dentalNotationFormat',
                          defaultMessage: 'Dental notation format',
                        })}
                      </p>

                      <Select
                        options={DENTAL_NOTATIONS}
                        value={notationFormat}
                        onChange={(value) =>
                          handleChangeNotationFormat(
                            value as DentalNotationFormat,
                          )
                        }
                      />
                    </div>
                  </WidgetCard>
                </Column>
              </WidgetLayout>
            </Tabs.Content>

            <Tabs.Content value={TabValue.Staff} className={styles.tabContent}>
              <StaffMembers />
            </Tabs.Content>

            <Tabs.Content
              value={TabValue.BillingInfo}
              className={cn(styles.tabContent, styles.billing)}
            >
              <WidgetLayout>
                <Column className={styles.billingColumn}>
                  <CurrentSubscription />

                  <Packages />
                </Column>

                <Column className={styles.billingColumn}>
                  <BillingInformation />

                  <Invoices />
                </Column>
              </WidgetLayout>
            </Tabs.Content>

            {isShopAvailable && (
              <Tabs.Content
                value={TabValue.SubscriptionPlans}
                className={cn(styles.tabContent, styles.subscriptionPlans)}
              >
                <WidgetLayout>
                  <SubscriptionPlans />
                </WidgetLayout>
              </Tabs.Content>
            )}
          </Tabs.Root>

          <AddStaffModal />

          <EditCompanyInfoModal
            organizationID={organization?.ID ?? ''}
            currentCountry={currentCountry}
          />

          <PermissionsModal />

          <GetFullAccessModal />

          <BillingInformationModal />

          <AboutLabelingModal />

          <RemoveAssetModal
            assetID={currentAssetID}
            isOpen={isRemoveAssetModalOpen}
            onCancel={closeRemoveAssetModal}
          />
        </Layout.Main>
      </Layout.Content>

      <Layout.Footer>
        <Footer />
      </Layout.Footer>
    </Layout>
  );
};
